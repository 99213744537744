<template>
    <div>
        <b-alert :variant="loading?'primary':'danger'" :show="showAlert">
            <div class="alert-body py-3 px-2">
                <span><strong v-if="loading">Veriler yükleniyor. <b-spinner label="Loading..." /></strong><strong v-else>Veri bulunamadı.</strong></span>
            </div>
        </b-alert>

        <b-row v-if="!loading">
            <b-col sm="12">
                <statistics-group :items="groupData" />
            </b-col>
            <b-col md="6" lg="3" v-for="(item, key) in companyCounts" :key="key">
                <horizontal-card :statistic="item.count" :icon="item.fileName" :statisticTitle="item.name" :color="$store.state.appConfig.compTypeColors[item.id]" />
            </b-col>

            <b-col sm="12">
                <b-card title="Son kayıt olan kullanıcılar">
                    <vue-good-table
                        :columns="userTableColumns"
                        :rows="lastUsers"
                        :sort-options="{
                            enabled: false
                        }"
                        compactMode
                    >
                        <template
                            slot="table-row"
                            slot-scope="props"
                        >

                            <!-- Column: Name -->
                            <div v-if="props.column.field === 'name'" class="text-nowrap text-small">
                                {{props.row.name+' '+props.row.surname}}
                            </div>
                            <div v-else-if="props.column.field === 'email'" class="text-nowrap text-small">
                                <a :href="'mailto:'+props.row.email">{{props.row.email}}</a>
                            </div>
                            <div v-else-if="props.column.field === 'gsm'" class="text-nowrap text-small">
                                <a :href="'tel:'+props.row.gsm">{{props.row.gsm}}</a>
                            </div>

                            <!-- Column: Status -->
                            <div v-else-if="props.column.field === 'fileName'">
                                <b-avatar :src="$basePath+props.row.fileName" v-if="props.row.fileName" />
                                <b-avatar v-else variant="primary">
                                    <span class="d-flex align-items-center">
                                        <feather-icon
                                            icon="UserIcon"
                                            size="16"
                                        />
                                    </span>
                                </b-avatar>
                            </div>

                            <!-- Column: Common -->
                            <div v-else class="text-small">
                                {{ props.formattedRow[props.column.field] }}
                            </div>
                        </template>
                    </vue-good-table>
                </b-card>
            </b-col>
            <b-col sm="12">
                <b-card title="Son kayıt olan firmalar">
                    <vue-good-table
                        :columns="companyTableColumns"
                        :rows="latestCompanies"
                        :sort-options="{
                            enabled: false
                        }"
                        compactMode
                    >
                        <template
                            slot="table-row"
                            slot-scope="props"
                        >

                            <!-- Column: Name -->
                            <div v-if="props.column.field === 'companyName'" class="text-small">
                                {{props.row.companyName}}
                            </div>
                            <div v-else-if="props.column.field === 'email'" class="text-small">
                                <a :href="'mailto:'+props.row.email">{{props.row.email}}</a>
                            </div>
                            <div v-else-if="props.column.field === 'gsm'" class="text-small">
                                <a :href="'tel:'+props.row.gsm">{{props.row.gsm}}</a>
                            </div>
                            <div v-else-if="props.column.field === 'user'" class="text-small">
                                {{ props.row.user.name+' '+props.row.user.surname }}
                            </div>
                            <div v-else-if="props.column.field === 'type'" class="text-small">
                                <span class="typeBadge" :style="{backgroundColor: getCompanyColor(props.row.type, .13), color: getCompanyColor(props.row.type, 1)}">
                                    {{ types[props.row.type] }}
                                </span>
                            </div>

                            <div v-else-if="props.column.field === 'logo'">
                                <b-avatar :src="$basePath+props.row.logo" v-if="props.row.logo" />
                                <b-avatar v-else variant="primary">
                                    <span class="d-flex align-items-center">
                                        <feather-icon
                                            icon="UserIcon"
                                            size="16"
                                        />
                                    </span>
                                </b-avatar>
                            </div>

                            <!-- Column: Common -->
                            <div v-else class="text-small">
                                {{ props.formattedRow[props.column.field] }}
                            </div>
                        </template>
                    </vue-good-table>
                </b-card>
            </b-col>

        </b-row>

    </div>
</template>

<script>

import {
    BAlert,
    BSpinner,
    BRow,
    BCol,
    BCard,
    BAvatar

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

import statisticsGroup from './CardStatisticGroup'
import horizontalCard from './CardStatisticsHorizontal'

export default {
    name: 'Admin',
    data(){
        return {
            userCounts: false,
            lastUsers: false,
            latestCompanies: false,
            totalSales: false,
            totalProducts: false,
            totalMembershipPayments: false,

            loaders: {
                userCounts: false,
                lastUsers: false,
                latestCompanies: false,
                totalSales: false,
                totalProducts: false,
                totalMembershipPayments: false,
            },

            types:{
                2: 'Veteriner',
                3: 'Pet Shop',
                4: 'Pet Hotel',
                5: 'Rastaurant/Cafe',
                6: 'Eğitim Merkezi',
                7: 'Otel',
                8: 'Barınak',
                9: 'Pet Kuaför'
            },

            userTableColumns: [
                {
                    label: '#',
                    field: 'id',
                    width: '80px',
                },
                {
                    label: 'Avatar',
                    field: 'fileName'
                },
                {
                    label: 'Adı',
                    field: 'name'
                },
                {
                    label: 'E-Posta',
                    field: 'email'
                },
                {
                    label: 'Telefon',
                    field: 'gsm'
                },
            ],
            companyTableColumns: [
                {
                    label: '#',
                    field: 'id',
                    width: '80px',
                },
                {
                    label: 'Logo',
                    field: 'logo'
                },
                {
                    label: 'Şirket Adı',
                    field: 'companyName'
                },
                {
                    label: 'E-Posta',
                    field: 'email'
                },
                {
                    label: 'Kullanıcı',
                    field: 'user'
                },
                {
                    label: 'Telefon',
                    field: 'gsm'
                },
                {
                    label: 'Şirket Türü',
                    field: 'type'
                },
            ],
        }
    },
    created(){
        let t = this


        t.$http.get('/admin/misc/userCount')
            .then(res => {
                if(res.data.status){
                    t.userCounts = res.data.data
                }
            }).catch(error=>{
                console.log(error)
            }).then(()=>{
                t.loaders.userCounts = true
            })

        t.$http.get('/admin/misc/latestUsers/10')
            .then(res => {
                if(res.data.status){
                    t.lastUsers = res.data.data
                }
            }).catch(error=>{
                console.log(error)
            }).then(()=>{
                t.loaders.lastUsers = true
            })

        t.$http.get('/admin/misc/latestCompanies/10')
            .then(res => {
                if(res.data.status){
                    t.latestCompanies = res.data.data
                }
            }).catch(error=>{
                console.log(error)
            }).then(()=>{
                t.loaders.latestCompanies = true
            })

        t.$http.get('/admin/misc/totalSales/30') /*son 30 gün.*/
            .then(res => {
                if(res.data.status){
                    t.totalSales = res.data.data.count
                }
            }).catch(error=>{
                console.log(error)
            }).then(()=>{
                t.loaders.totalSales = true
            })

        t.$http.get('/admin/misc/totalProducts')
            .then(res => {
                if(res.data.status){
                    t.totalProducts = res.data.data.count
                }
            }).catch(error=>{
                console.log(error)
            }).then(()=>{
                t.loaders.totalProducts = true
            })

        t.$http.get('/admin/misc/totalMembershipPayments/30') /*Son 30 gün*/
            .then(res => {
                if(res.data.status){
                    t.totalMembershipPayments = res.data.data.price
                }
            }).catch(error=>{
                console.log(error)
            }).then(()=>{
                t.loaders.totalMembershipPayments = true
            })
    },
    computed:{
        loading(){
            let t = this
            let keys = Object.keys(t.loaders)
            let status = true
            for(let i = 0; i < keys.length; i++){
                status = !t.loaders[keys[i]] ? false : status
            }
            return !status
        },
        showAlert(){
            let t = this
            let keys = Object.keys(t.loaders)
            let status = false
            for(let i = 0; i < keys.length; i++){
                status = t[keys[i]]===false ? true : status
            }
            return status
        },
        groupData(){
            return [
                {
                    icon: 'UserIcon',
                    color: 'light-info',
                    title: this.totalUserCount,
                    subtitle: 'Üye',
                    customClass: 'mb-2 mb-xl-0',
                    responsive: {
                        sm: 6,
                        lg: 'auto'
                    }
                },
                {
                    icon: 'UsersIcon',
                    color: 'light-danger',
                    title: this.totalCompanyCount,
                    subtitle: 'Firma',
                    customClass: 'mb-2 mb-sm-0',
                    responsive: {
                        sm: 6,
                        lg: 'auto'
                    }
                },
                {
                    icon: 'TagIcon',
                    color: 'light-warning',
                    title: this.totalProducts,
                    subtitle: 'Ürün',
                    customClass: 'mb-2 mb-xl-0',
                    responsive: {
                        sm: 4,
                        lg: 'auto'
                    }
                },
                {
                    icon: 'TrendingUpIcon',
                    color: 'light-primary',
                    title: this.totalSales,
                    subtitle: 'Sipariş',
                    customClass: 'mb-2 mb-xl-0',
                    responsive: {
                        sm: 4,
                        lg: 'auto'
                    }
                },
                {
                    icon: 'DollarSignIcon',
                    color: 'light-success',
                    title: this.totalMembershipPayments+'₺',
                    subtitle: 'Abonelik Geliri',
                    customClass: '',
                    responsive: {
                        sm: 4,
                        lg: 'auto'
                    }
                },
            ]
        },
        totalUserCount(){
            let item = this.userCounts.filter(e=>{return e.id === 1 || e.id === "1"})
            return item[0].count
        },
        totalCompanyCount(){
            let items = this.companyCounts
            let total = 0
            for(let i = 0; i<items.length; i++){
                total += parseInt(items[i].count)
            }
            return total
        },
        companyCounts(){
            let items = this.userCounts.filter(e=>{return e.id !== 1 && e.id !== "1"})
            return items
        }
    },
    methods:{
        getCompanyColor(type, opacity){
            let hex = this.$store.state.appConfig.compTypeColors[type]
            let c = hex.substring(1).split('');
            if(c.length== 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+opacity+')';
        }
    },
    components: {
        BAlert,
        BSpinner,
        BRow,
        BCol,
        BCard,
        BAvatar,
        statisticsGroup,
        horizontalCard,
        VueGoodTable,
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
.text-small,
table.vgt-table{
    font-size: 12px;
}
.typeBadge{
    display: inline-block;
    border-radius: 5px;
    padding: 5px;
}
</style>
